import { QuestionCircleIcon } from '@fluentui/react-icons-northstar'
import {
  Alert,
  Box,
  Button,
  Dialog,
  Divider,
  Ref,
  Text,
  TextArea,
  Tooltip,
} from '@fluentui/react-northstar'
import * as Graph from '@microsoft/microsoft-graph-types'
import {
  AadUserConversationMember,
  NullableOption,
} from '@microsoft/microsoft-graph-types'
import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import { AppThunk, RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import {
  CustomFieldValue,
  DefaultFields,
  TicketStatus,
  TicketStatusLabels,
  Tickets,
  customFieldMaxLength,
} from '../../consts'
import { format } from '../../utils'
import { addAlert } from '../alert/alertsSlice'
import { customFieldSelector, fetchCustomFields } from './customFieldSlice'
import {
  customFieldValueSelector,
  fetchCustomFieldValues,
  setCustomFieldValueInitialize,
} from './customFieldValueSlice'
import TicketAssignedUserDialog from './TicketAssignedUserDialog'
import TicketChannelDialog from './TicketChannelDialog'
import styles from './TicketProperty.module.css'
import TicketPropertyCustomFieldComponent from './ticketPropertyCustomField/TicketPropertyCustomFieldComponent'
import TicketSelfAssignButton from './TicketSelfAssignButton'
import {
  Ticket,
  switchRequiredAssignedUserConfirm,
  updateTicket,
} from './ticketSlice'
import TicketStatusLabel from './TicketStatusLabel'

const delayTime = 2000

let doDebounce: (latestTime: number) => void
const debounce = (
  timeOutId: NodeJS.Timeout,
  delayTime: number,
  previousTime: number,
  latestTime: number
) => {
  if (latestTime - previousTime < delayTime) {
    clearTimeout(timeOutId)
  }
}

type AssignedUserDropdownListObject = {
  value: string
  header: string | null | undefined
}

export interface TicketPropertyProps {
  deleteTicketButton?: React.ReactNode
  statusDisable: boolean
}

const TicketProperty: React.FC<TicketPropertyProps> = (
  props: TicketPropertyProps
) => {
  const { deleteTicketButton, statusDisable: statusEditable } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const memberState = useSelector((state: RootState) => state.member)
  const usersState = useSelector((state: RootState) => state.users)
  const authState = useSelector((state: RootState) => state.auth)
  const customFieldValueState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFields = useSelector(customFieldSelector.selectAll)
  const customFieldValues = useSelector(customFieldValueSelector.selectAll)
  const [assignedUserSearchQuery, setAssignedUserSearchQuery] =
    useState<string>()
  const [isAssignedUserDropdownOpen, setIsAssignedUserDropdownOpen] =
    useState(false)

  useEffect(() => {
    dispatch(setCustomFieldValueInitialize())
  }, [dispatch])

  // CustomFieldValueの共通アラート
  // カスタムフィールドの値のAPIのみがデグレなどの影響で異常状態の時にチケット詳細全てが閲覧不可にならないようにリダイレクトはせずAlert
  useEffect(() => {
    if (customFieldValueState.error != null) {
      dispatch(
        addAlert({
          id: parseInt(ticketId, 10),
          title: CustomFieldValue.ClientErrorMessage.Unknown,
          type: 'custom_field_value_error',
        })
      )
    }
  }, [dispatch, customFieldValueState.error, ticketId])

  useEffect(() => {
    if (customFieldValueState.isInitializing) {
      dispatch(fetchCustomFieldValues(parseInt(ticketId, 10)))
    }
  }, [dispatch, ticketId, customFieldValueState.isInitializing])

  useEffect(() => {
    dispatch(fetchCustomFields(0, customFieldMaxLength))
  }, [dispatch])

  const members = memberState.ids.map(
    (id) => memberState.entities[id] as AadUserConversationMember
  )
  const [confirmModalStatus, setConfirmModalStatus] = useState(false)
  const [reqAssignedUserId, setReqAssignedUserId] = useState(
    Tickets.RequestedAssignedUser.InitialState
  )
  const [confirmModalChannel, setConfirmModalChannel] = useState(false)

  const ticket = ticketState.entities[ticketId]
  const loginUserId = authState.context?.user?.id

  const [subject, setSubject] = useState<string>()

  const handleSubjectChanges = (
    dispatch: Dispatch<AppThunk<void>>,
    ticketId: number,
    subject: string
  ) => {
    dispatch(updateTicket({ id: ticketId, subject: subject }, true))
  }

  const handleStatesChanges = (
    dispatch: Dispatch<AppThunk<void>>,
    ticketId: number,
    isManual: boolean,
    status: string
  ) => {
    if (!isManual && status === TicketStatus.completed) {
      setConfirmModalStatus(true)
      return
    }
    dispatch(updateTicket({ id: ticketId, status: status }, true))
  }

  const handleAssignedUserChanges = (
    dispatch: Dispatch<AppThunk<void>>,
    ticketId: number,
    assignedUserId: string
  ) => {
    setReqAssignedUserId(assignedUserId)
    dispatch(
      updateTicket({ id: ticketId, assignedUserId: assignedUserId }, false)
    )
  }

  const handleCancelAssignedUserChanges = () => {
    // キャンセル時はUIの選択されている名前を初期値に戻す
    setReqAssignedUserId(Tickets.RequestedAssignedUser.InitialState)
    dispatch(switchRequiredAssignedUserConfirm())
  }

  const initializing = usersState.meId == null || members.length === 0

  const currentAssignedUserSearchQuery =
    loginUserId == null || ticket == null
      ? ''
      : defaultSearchQuery(members, reqAssignedUserId, loginUserId, ticket)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setSubject((s) => {
      // When subject is not initlized, try to retrieve the value from ticket
      if (s == null) {
        return ticket?.subject
      }
      // After subject is initilized, do nothing
      return s
    })
  }, [ticket?.subject])

  useEffect(() => {
    setAssignedUserSearchQuery(currentAssignedUserSearchQuery)
  }, [currentAssignedUserSearchQuery])

  useEffect(() => {
    const handleUpdate = () => {
      if (!ticket?.id) return
      handleSubjectChanges(dispatch, ticket.id, String(subject))
    }
    // popstate(ブラウザバック)はrefがnullになり値が取得できないのでこちらでaddEventListenerしている
    window.addEventListener('popstate', handleUpdate)
    return () => {
      window.removeEventListener('popstate', handleUpdate)
    }
  }, [dispatch, subject, ticket?.id])

  if (memberState.error != null) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (!ticketState.error.isEmpty() && !ticketState.error.isNetworkErr()) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (ticket == null) return null
  if (loginUserId == null) return null

  return (
    <>
      <Text weight="bold">{DefaultFields.Subject.name}</Text>
      <Ref innerRef={textAreaRef}>
        <TextArea
          rows={3}
          resize="vertical"
          value={subject}
          maxLength={1000}
          onChange={(_, e) => {
            setSubject(String(e?.value))
            const currentTime = new Date().getTime()
            const timeOutId = setTimeout(() => {
              handleSubjectChanges(dispatch, ticket.id, String(e?.value))
            }, delayTime)

            if (doDebounce != null) {
              doDebounce(currentTime)
            }
            doDebounce = debounce.bind(null, timeOutId, delayTime, currentTime)
          }}
          onBlur={() => {
            handleSubjectChanges(dispatch, ticket.id, String(subject))
          }}
        />
      </Ref>
      <Box>
        <Text weight="bold" className={styles.statusText}>
          {DefaultFields.Status.name}
        </Text>
        <Tooltip
          trigger={
            <Button
              text
              iconOnly
              icon={<QuestionCircleIcon />}
              className={styles.statusHelpButton}
            />
          }
          position="below"
          align="center"
          pointing
          content={
            <Box className={styles.tooltipContent}>
              <Box>
                <TicketStatusLabel ticketStatus={TicketStatus.new} />
                <Text
                  className={styles.statusText}
                  content=" 問い合わせに何のアクションも行われていないことを意味します。"
                />
              </Box>
              <Divider />
              <Box>
                <TicketStatusLabel ticketStatus={TicketStatus.working} />
                <Text
                  className={styles.statusText}
                  content=" 問い合わせが担当者に割り当てられ、処理中であることを示します。担当者によるアクションを待っている状態です。"
                />
              </Box>
              <Divider />
              <Box>
                <TicketStatusLabel ticketStatus={TicketStatus.pending} />
                <Text
                  className={styles.statusText}
                  content=" 担当者が依頼者からの応答を待っていることを示します。依頼者から応答があると、ステータスは自動的に「対応中」に再設定されます。"
                />
              </Box>
              <Divider />
              <Box>
                <TicketStatusLabel ticketStatus={TicketStatus.completed} />
                <Text
                  className={styles.statusText}
                  content=" 問い合わせが解決したことを示します。一度「解決済み」に変更すると、別のステータスに変更することや、依頼者とメッセージを行うことができなくなります。"
                />
              </Box>
            </Box>
          }
        />
      </Box>
      <Dropdown
        fluid
        styles={{ width: '100%' }}
        value={[ticket.status]}
        disabled={statusEditable}
        items={[
          {
            value: TicketStatus.new,
            header: TicketStatusLabels[TicketStatus.new],
          },
          {
            value: TicketStatus.working,
            header: TicketStatusLabels[TicketStatus.working],
          },
          {
            value: TicketStatus.pending,
            header: TicketStatusLabels[TicketStatus.pending],
          },
          {
            value: TicketStatus.completed,
            header: TicketStatusLabels[TicketStatus.completed],
          },
        ]}
        onValueChange={(values) =>
          handleStatesChanges(dispatch, ticket.id, ticket.isManual, values[0])
        }
      />
      <Text weight="bold">{DefaultFields.AssignedUserId.name}</Text>
      <Dropdown
        initializing={initializing}
        fluid
        search
        clearable
        noResultsMessage="マッチする担当者がいません"
        styles={{ width: '100%' }}
        defaultSearchQuery={currentAssignedUserSearchQuery}
        value={displayedAssignedUserID(reqAssignedUserId, ticket)}
        placeholder="選択してください"
        items={assignedUserDropdownListItems(
          members,
          reqAssignedUserId,
          loginUserId,
          ticket
        )}
        onValueChange={(values) => {
          const assignedUserId = values[0]
          if (assignedUserId === undefined) {
            // "noResultsMessage"が適用された検索状態
            setAssignedUserSearchQuery('')
            return
          }
          handleAssignedUserChanges(dispatch, ticket.id, values[0])
          setIsAssignedUserDropdownOpen(false)
          // 特定のユーザーから(未設定)に変更する際にダイアログで担当者変更を確定するとDropdown領域にフォーカスが戻る。
          // フォーカスが戻るとonInputFocusが再び発火し、検索クエリの文字列を空文字にしてしまう。これを回避するため、値が変更されたら強制blurを行っている。
          if (document.activeElement != null)
            (document.activeElement as HTMLElement).blur()
        }}
        // 未設定の場合はフォーカス時に検索クエリを空文字にし、同時に選択可能なリストをOpenにする
        onInputFocus={() => {
          if (
            displayedAssignedUserID(reqAssignedUserId, ticket)[0] === '' ||
            assignedUserSearchQuery === ''
          ) {
            setAssignedUserSearchQuery('')
            setIsAssignedUserDropdownOpen(true)
          }
        }}
        // 未設定の場合はblur時に検索クエリに(未設定)という文言を挿入する
        onBlur={() => {
          setIsAssignedUserDropdownOpen(false)
          if (displayedAssignedUserID(reqAssignedUserId, ticket)[0] === '') {
            setAssignedUserSearchQuery(Tickets.Users.UserNotIndicated)
          }
        }}
        // Dropdownの領域をクリックした時に選択リストをOpenにする
        onClick={() => {
          setIsAssignedUserDropdownOpen(true)
        }}
        open={isAssignedUserDropdownOpen}
        searchQuery={assignedUserSearchQuery}
        onSearchQueryChange={(_, d) => {
          setAssignedUserSearchQuery(d.searchQuery)
          if (d.searchQuery === '') {
            setIsAssignedUserDropdownOpen(true)
          }
        }}
      />

      <TicketSelfAssignButton
        onClick={() =>
          handleAssignedUserChanges(dispatch, ticket.id, loginUserId)
        }
        initializing={initializing}
      />
      {authState.availableFeatures?.multiChannel && (
        <Button
          content="別のチャネルに移動"
          text
          onClick={() => {
            setConfirmModalChannel(true)
          }}
        />
      )}
      <Divider />
      <Text weight="bold">問い合わせID</Text>
      <Text>{ticket.keyId}</Text>
      <Text weight="bold">{DefaultFields.CreatedAt.name}</Text>
      <Text>{format(ticket.createdAt, 'yyyy/MM/dd HH:mm')}</Text>
      <Text weight="bold">解決日</Text>
      <Text>{format(ticket.completedAt, 'yyyy/MM/dd HH:mm', '--')}</Text>
      <Dialog
        open={confirmModalStatus}
        onConfirm={() => {
          dispatch(
            updateTicket(
              {
                id: ticket.id,
                status: TicketStatus.completed,
              },
              true
            )
          )
          setConfirmModalStatus(false)
        }}
        onCancel={() => setConfirmModalStatus(false)}
        cancelButton="キャンセル"
        confirmButton="確定"
        content="一度「解決済み」に変更すると、別のステータスに変更することや、質問者とメッセージを行うことができなくなります。実行してよろしいですか？"
      />
      <TicketAssignedUserDialog
        ticketId={ticket.id}
        onConfirm={() => {
          dispatch(
            updateTicket(
              {
                id: parseInt(ticketId, 10),
                assignedUserId: reqAssignedUserId,
              },
              true
            )
          )
          dispatch(switchRequiredAssignedUserConfirm())
        }}
        onCancel={() => handleCancelAssignedUserChanges()}
      />
      <TicketChannelDialog
        open={confirmModalChannel}
        onConfirm={({ groupId, channelId }) => {
          setConfirmModalChannel(false)
          history.push(
            `/tickets/${ticketId}/transfer?group_id=${groupId}&channel_id=${channelId}`
          )
        }}
        onCancel={() => setConfirmModalChannel(false)}
      />
      <Divider />
      {customFieldValueState.error != null && (
        <Alert
          styles={{ padding: '8px' }}
          content={CustomFieldValue.ClientErrorMessage.Unknown}
          variables={{
            urgent: true,
          }}
        />
      )}
      {customFields.length > 0 &&
        !customFieldValueState.isInitializing &&
        customFields.map((customField) => (
          <TicketPropertyCustomFieldComponent
            key={customField.id}
            ticketId={ticket.id}
            field={customField}
            fieldValues={customFieldValues}
          />
        ))}
      {deleteTicketButton}
    </>
  )
}

// 担当者をアサインする時に開くDropdownで表示される名前のマッパー
const createAssignedUserDisplayName = (
  member: Graph.AadUserConversationMember,
  loginUserId: string,
  reqAssignedUserId: string,
  assignedUserId: string
): string | NullableOption<string> | undefined => {
  if (member.userId == null) return Tickets.Users.UserNotExists
  if (member.userId.length === 0) return Tickets.Users.UserNotIndicated

  // 1. 氏名だけが表示される
  // ログイン中のuserIdとメンバーのuserIdが異なる場合
  // ログイン中のuserIdとチケットの担当者userIdが異なる場合
  // ログイン中のUserIdと変更したい担当者userIdが同じ場合
  if (
    loginUserId !== member.userId ||
    loginUserId === assignedUserId ||
    loginUserId === reqAssignedUserId
  )
    return member.displayName
  // 2. 自分に割り当てと一緒に表示される
  return member.displayName + Tickets.Users.SelfAssignSuffix
}

// ユーザーがUI上で見ている担当者のID
const displayedAssignedUserID = (
  reqAssignedUserId: string,
  ticket: Ticket
): string[] => {
  // 初期値
  if (reqAssignedUserId === Tickets.RequestedAssignedUser.InitialState)
    return [ticket.assignedUserId]
  // 未割当に変更しようとする時
  if (reqAssignedUserId === '') return ['']
  // 誰かに担当者を変更しようとする時
  return [reqAssignedUserId]
}

const assignedUserDropdownListItems = (
  members: AadUserConversationMember[],
  reqAssignedUserId: string,
  loginUserId: string,
  ticket: Ticket
): AssignedUserDropdownListObject[] => {
  const items = [
    {
      value: '',
      header: Tickets.Users.UserNotIndicated,
    },
    ...members.map((m) => ({
      value: m.userId || '',
      header: createAssignedUserDisplayName(
        m,
        loginUserId,
        reqAssignedUserId,
        ticket.assignedUserId
      ),
    })),
  ]
  // 自分の名前をリストの先頭に移動する
  const initialObjIdx = items.findIndex((i) => i.value === loginUserId)
  if (initialObjIdx === -1) {
    return items
  }
  const initialObj = items[initialObjIdx]
  items.splice(initialObjIdx, 1)
  items.unshift(initialObj)
  return items
}

const defaultSearchQuery = (
  members: AadUserConversationMember[],
  reqAssignedUserId: string,
  loginUserId: string,
  ticket: Ticket
): string => {
  const items = assignedUserDropdownListItems(
    members,
    reqAssignedUserId,
    loginUserId,
    ticket
  )
  const userID = displayedAssignedUserID(reqAssignedUserId, ticket)[0]
  const defaultSearchQuery = items.find((i) => i.value === userID)?.header
  if (typeof defaultSearchQuery === 'string') {
    return defaultSearchQuery
  }
  return ''
}

export default TicketProperty
