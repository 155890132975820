import { grpc } from '@improbable-eng/grpc-web'
import * as microsoftTeams from '@microsoft/teams-js'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { AppThunk } from '../../app/store'
import {
  CustomFieldType,
  DefaultFields,
  FaqStatus,
  Sort,
  TicketStatus,
  Tickets,
} from '../../consts'
import tab_config_pb from '../../proto/tab_config_pb'
import tab_config_pb_service from '../../proto/tab_config_pb_service'
import tab_pb from '../../proto/tab_pb'
import tab_pb_service from '../../proto/tab_pb_service'
import { TicketCustomField } from '../../proto/ticket_custom_field_pb'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

// status, assignedUserId
export type StringValuesFilter = {
  values: string[]
}

export interface CreatedAtValueFilter {
  startDate?: string
  startHour?: string
  startMinute?: string
  endDate?: string
  endHour?: string
  endMinute?: string
}

// select, multi select
export interface CustomSelectValuesFilter {
  values: string[]
}

export interface CustomCheckBoxValuesFilter {
  values: number[]
}

// text, text area
export interface CustomTextValueFilter {
  value: string
}

export interface CustomNumberValueFilter {
  minValue?: number
  maxValue?: number
}

export interface CustomDateValueFilter {
  startDate?: string
  endDate?: string
}

export type Filter =
  | StringValuesFilter
  | CreatedAtValueFilter
  | CustomSelectValuesFilter
  | CustomDateValueFilter
  | CustomNumberValueFilter
  | CustomTextValueFilter
  | CustomCheckBoxValuesFilter

export interface FieldConfig {
  identifierName: string
  ticketCustomFieldId: number | null
}
export interface FilterConfig {
  identifierName: string
  ticketCustomFieldId: number | null
  filter: Filter
}

export interface SortConfig {
  identifierName: string
  ticketCustomFieldId: number | null
  direction: string
}

export interface DefaultTabConfig {
  id: string
  fields: FieldConfig[]
  filters: FilterConfig[]
  sort: SortConfig
  error: {
    filterIdentifierNames: string[]
  }
  initialize: boolean
  isDefaultTab: boolean
}

const initialState: DefaultTabConfig = {
  id: '',
  fields: [],
  filters: [],
  sort: {
    identifierName: DefaultFields.ReceivedAt.value,
    ticketCustomFieldId: null,
    direction: Sort.Desc.value,
  },
  error: { filterIdentifierNames: [] },
  initialize: false,
  isDefaultTab: false,
}

export const isStringValueFilter = (
  filter: Filter
): filter is StringValuesFilter => {
  return 'values' in filter
}

export const isCreatedAtValueFilter = (
  filter: Filter
): filter is CreatedAtValueFilter => {
  return (
    'startDate' in filter ||
    'startHour' in filter ||
    'startMinute' in filter ||
    'endDate' in filter ||
    'endHour' in filter ||
    'endMinute' in filter
  )
}

export const isCustomTextValueFilter = (
  filter: Filter
): filter is CustomTextValueFilter => {
  return 'value' in filter
}

export const isCustomSelectValueFilter = (
  filter: Filter
): filter is CustomSelectValuesFilter => {
  return 'values' in filter
}

export const isCustomNumberValueFilter = (
  filter: Filter
): filter is CustomNumberValueFilter => {
  return 'minValue' in filter || 'maxValue' in filter
}

export const isCustomCheckboxValueFilter = (
  filter: Filter
): filter is CustomCheckBoxValuesFilter => {
  return 'values' in filter
}

export const isCustomDateValueFilter = (
  filter: Filter
): filter is CustomDateValueFilter => {
  return 'startDate' in filter || 'endDate' in filter
}

const initializeFieldConfigs = (
  customFields: TicketCustomField.AsObject[]
): FieldConfig[] => {
  // 初期化時にはチケット一覧のヘッダーを全て設定する
  return [
    ...defaultFieldConfigs,
    ...customFields.map((customField) => {
      return {
        identifierName: customField.name,
        ticketCustomFieldId: customField.id,
      }
    }),
  ]
}

const defaultFieldConfigs = [
  { identifierName: DefaultFields.KeyId.name, ticketCustomFieldId: null },
  {
    identifierName: DefaultFields.Requester.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.Subject.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.AssignedUserId.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.Status.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.CreatedAt.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.ReceivedAt.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.SentAt.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.FaqStatus.name,
    ticketCustomFieldId: null,
  },
  {
    identifierName: DefaultFields.FaqCreator.name,
    ticketCustomFieldId: null,
  },
]

export const tabConfigSlice = createSlice({
  name: 'tabConfig',
  initialState,
  reducers: {
    setInitialState(
      state,
      action: PayloadAction<{
        customFields: TicketCustomField.AsObject[]
      }>
    ) {
      state.filters.push({
        identifierName: DefaultFields.Status.value,
        ticketCustomFieldId: null,
        filter: {
          values: [TicketStatus.new, TicketStatus.working],
        },
      })
      state.filters.push({
        identifierName: DefaultFields.AssignedUserId.value,
        ticketCustomFieldId: null,
        filter: {
          values: ['', Tickets.Users.loginUserId],
        },
      })
      state.fields = initializeFieldConfigs(action.payload.customFields)
      state.initialize = true
    },
    addField(
      state,
      action: PayloadAction<{
        identifierName: string
        ticketCustomFieldId: number | null
      }>
    ) {
      const { identifierName, ticketCustomFieldId } = action.payload
      state.fields.push({
        identifierName: identifierName,
        ticketCustomFieldId: ticketCustomFieldId,
      })
    },
    moveFields(
      state,
      action: PayloadAction<{
        dragIndex: number
        hoverIndex: number
      }>
    ) {
      const fields = state.fields
      const a = fields[action.payload.hoverIndex]
      const b = fields[action.payload.dragIndex]
      fields[action.payload.dragIndex] = a
      fields[action.payload.hoverIndex] = b
      state.fields = fields
    },
    removeField(state, action: PayloadAction<{ tabConfigFieldIndex: number }>) {
      state.fields.splice(action.payload.tabConfigFieldIndex, 1)

      state.sort = {
        identifierName: DefaultFields.ReceivedAt.value,
        ticketCustomFieldId: null,
        direction: Sort.Desc.value,
      }

      state.initialize = true
    },
    setTabConfigSortIdentifierName(state, action: PayloadAction<string>) {
      state.sort.identifierName = action.payload
    },
    setTabConfigSortTicketCustomFieldId(
      state,
      action: PayloadAction<number | null>
    ) {
      state.sort.ticketCustomFieldId = action.payload
    },
    setTabConfigSortDirection(state, action: PayloadAction<string>) {
      state.sort.direction = action.payload
    },
    setTabConfigName(
      state,
      action: PayloadAction<{
        identifierName: string
        ticketCustomFieldId: number | null
      }>
    ) {
      const { identifierName, ticketCustomFieldId } = action.payload
      state.filters.push({
        identifierName: identifierName,
        ticketCustomFieldId: ticketCustomFieldId,
        filter:
          identifierName === DefaultFields.Status.value ||
          identifierName === DefaultFields.AssignedUserId.value
            ? { values: [] }
            : {},
      })
    },
    setStatuses(
      state,
      action: PayloadAction<{
        statuses: TicketStatus[]
        tabConfigFilterIndex: number
      }>
    ) {
      state.filters[action.payload.tabConfigFilterIndex].filter = {
        values: action.payload.statuses,
      }
    },
    setAssignedUserIds(
      state,
      action: PayloadAction<{
        assignedUserIds: string[]
        tabConfigFilterIndex: number
      }>
    ) {
      state.filters[action.payload.tabConfigFilterIndex].filter = {
        values: action.payload.assignedUserIds,
      }
    },
    setStartDateTime(
      state,
      action: PayloadAction<{
        createdAtFilter: {
          startDate?: string
          startHour?: string
          startMinute?: string
          endDate?: string
          endHour?: string
          endMinute?: string
        }
        tabConfigFilterIndex: number
      }>
    ) {
      const { createdAtFilter, tabConfigFilterIndex } = action.payload
      state.filters[tabConfigFilterIndex].filter = createdAtFilter
    },
    setCustomSelectFilter(
      state,
      action: PayloadAction<{
        values: string[]
        customFieldId: number
        tabConfigFilterIndex: number
      }>
    ) {
      state.filters[action.payload.tabConfigFilterIndex].filter = {
        values: action.payload.values,
      }
    },
    setCustomNumberFilterValue(
      state,
      action: PayloadAction<{
        customFieldId: number
        minValue?: number
        maxValue?: number
        tabConfigFilterIndex: number
      }>
    ) {
      const { customFieldId, minValue, maxValue, tabConfigFilterIndex } =
        action.payload
      const numberFilterValue: {
        minValue?: number
        maxValue?: number
      } = {}
      if (minValue != null) numberFilterValue.minValue = minValue
      if (maxValue != null) numberFilterValue.maxValue = maxValue
      state.filters[tabConfigFilterIndex].ticketCustomFieldId = customFieldId
      state.filters[tabConfigFilterIndex].filter = numberFilterValue
    },
    setCustomDateFilterValue(
      state,
      action: PayloadAction<{
        customFieldId: number
        startDate?: string
        endDate?: string
        tabConfigFilterIndex: number
      }>
    ) {
      const { customFieldId, startDate, endDate, tabConfigFilterIndex } =
        action.payload
      const dateFilterValue: {
        startDate?: string
        endDate?: string
      } = {}
      if (startDate != null) dateFilterValue.startDate = startDate
      if (endDate != null) dateFilterValue.endDate = endDate
      state.filters[tabConfigFilterIndex].ticketCustomFieldId = customFieldId
      state.filters[tabConfigFilterIndex].filter = dateFilterValue
    },
    setCustomTextFilterValue(
      state,
      action: PayloadAction<{
        customFieldId: number
        value: string
        tabConfigFilterIndex: number
      }>
    ) {
      const { customFieldId, value, tabConfigFilterIndex } = action.payload
      state.filters[tabConfigFilterIndex].ticketCustomFieldId = customFieldId
      state.filters[tabConfigFilterIndex].filter = {
        value,
      }
    },
    setCustomTextAreaFilterValue(
      state,
      action: PayloadAction<{
        customFieldId: number
        value: string
        tabConfigFilterIndex: number
      }>
    ) {
      const { customFieldId, value, tabConfigFilterIndex } = action.payload
      state.filters[tabConfigFilterIndex].ticketCustomFieldId = customFieldId
      state.filters[tabConfigFilterIndex].filter = {
        value,
      }
    },
    setCustomCheckboxFilterValue(
      state,
      action: PayloadAction<{
        customFieldId: number
        tabConfigFilterIndex: number
        values: Array<number>
      }>
    ) {
      const { customFieldId, tabConfigFilterIndex, values } = action.payload
      state.filters[tabConfigFilterIndex].ticketCustomFieldId = customFieldId
      state.filters[tabConfigFilterIndex].filter = {
        values,
      }
    },
    removeTabConfigName(
      state,
      action: PayloadAction<{ tabConfigFilterIndex: number }>
    ) {
      const filter = state.filters[action.payload.tabConfigFilterIndex]
      state.error.filterIdentifierNames =
        state.error.filterIdentifierNames.filter((f: string) => {
          return f !== filter.identifierName
        })
      state.filters.splice(action.payload.tabConfigFilterIndex, 1)
    },
    resetFilter(
      state,
      action: PayloadAction<{ tabConfigFilterIndex: number }>
    ) {
      state.filters[action.payload.tabConfigFilterIndex].filter = {}
    },
    getTabConfigOnMessage(
      state,
      action: PayloadAction<{
        uuid: string
        message: tab_config_pb.TabConfig.AsObject
        customFields: TicketCustomField.AsObject[]
      }>
    ) {
      const { tabFilterConfigs, tabConfigFieldsList, tabSortConfig } =
        action.payload.message
      state.id = action.payload.uuid
      state.isDefaultTab = action.payload.message.isDefaultTab
      if (
        tabFilterConfigs != null &&
        tabFilterConfigs.tabFiltersList.length > 0
      ) {
        state.filters = tabFilterConfigs.tabFiltersList.reduce(
          (results: FilterConfig[], f) => {
            if (f.ticketCustomFieldId !== 0) {
              const customField = action.payload.customFields.find((c) => {
                return c.id === f.ticketCustomFieldId
              })
              if (customField != null) {
                const identifierName = `custom.${f.ticketCustomFieldId}.${customField.name}`
                const ticketCustomFieldId = f.ticketCustomFieldId
                switch (customField.type) {
                  case CustomFieldType.select:
                  case CustomFieldType.multiselect:
                    results.push({
                      identifierName,
                      ticketCustomFieldId,
                      filter: {
                        values:
                          f.ticketFieldOneOfFilter?.stringFilter?.valuesList ??
                          [],
                      },
                    })
                    break
                  case CustomFieldType.checkbox:
                    results.push({
                      identifierName,
                      ticketCustomFieldId,
                      filter: {
                        values:
                          f.ticketFieldOneOfFilter?.checkboxFilter
                            ?.valuesList ?? [],
                      },
                    })
                    break
                  case CustomFieldType.number: {
                    if (f.ticketFieldOneOfFilter?.maxNumberFilter != null)
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          maxValue:
                            f.ticketFieldOneOfFilter.maxNumberFilter.value,
                        },
                      })
                    if (f.ticketFieldOneOfFilter?.minNumberFilter != null) {
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          minValue:
                            f.ticketFieldOneOfFilter.minNumberFilter.value,
                        },
                      })
                    }
                    if (f.ticketFieldOneOfFilter?.numberFilter != null) {
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          maxValue:
                            f.ticketFieldOneOfFilter.numberFilter.maxValue,
                          minValue:
                            f.ticketFieldOneOfFilter.numberFilter.minValue,
                        },
                      })
                    }
                    break
                  }
                  case CustomFieldType.date: {
                    if (f.ticketFieldOneOfFilter?.startDateFilter != null) {
                      // RFC3339形式の文字列を日付・時間・分の三つに分ける
                      const date =
                        f.ticketFieldOneOfFilter.startDateFilter.value.split(
                          'T'
                        )[0]
                      const formattedDate = date.replace(/-/g, '/')
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          startDate: formattedDate,
                        },
                      })
                    }
                    if (f.ticketFieldOneOfFilter?.endDateFilter != null) {
                      // RFC3339形式の文字列を日付・時間・分の三つに分ける
                      const date =
                        f.ticketFieldOneOfFilter.endDateFilter.value.split(
                          'T'
                        )[0]
                      const formattedDate = date.replace(/-/g, '/')
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          endDate: formattedDate,
                        },
                      })
                    }
                    if (f.ticketFieldOneOfFilter?.dateFilter != null) {
                      // RFC3339形式の文字列を日付・時間・分の三つに分ける
                      const startDate =
                        f.ticketFieldOneOfFilter.dateFilter.startDate.split(
                          'T'
                        )[0]
                      const formattedStartDate = startDate.replace(/-/g, '/')
                      // RFC3339形式の文字列を日付・時間・分の三つに分ける
                      const endDate =
                        f.ticketFieldOneOfFilter.dateFilter.endDate.split(
                          'T'
                        )[0]
                      const formattedEndDate = endDate.replace(/-/g, '/')
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          startDate: formattedStartDate,
                          endDate: formattedEndDate,
                        },
                      })
                    }
                    break
                  }
                  case CustomFieldType.text:
                    if (
                      f.ticketFieldOneOfFilter?.stringFilter != null &&
                      f.ticketFieldOneOfFilter.stringFilter.valuesList.length >
                        0
                    ) {
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          value:
                            f.ticketFieldOneOfFilter.stringFilter.valuesList[0],
                        },
                      })
                    }
                    break
                  case CustomFieldType.textarea:
                    if (
                      f.ticketFieldOneOfFilter?.textFilter?.stringValue != null
                    )
                      results.push({
                        identifierName,
                        ticketCustomFieldId,
                        filter: {
                          value:
                            f.ticketFieldOneOfFilter.textFilter.stringValue,
                        },
                      })
                    break
                  default:
                    break
                }
              }
            } else {
              switch (f.ticketFieldName) {
                case DefaultFields.AssignedUserId.value:
                case DefaultFields.Status.value:
                case DefaultFields.FaqStatus.value:
                  if (
                    f.ticketFieldOneOfFilter?.stringFilter != null &&
                    f.ticketFieldOneOfFilter?.stringFilter.valuesList.length > 0
                  ) {
                    results.push({
                      identifierName: f.ticketFieldName,
                      ticketCustomFieldId: null,
                      filter: {
                        values:
                          f.ticketFieldOneOfFilter.stringFilter.valuesList,
                      },
                    })
                  }
                  break
                case DefaultFields.CreatedAt.value: {
                  if (f.ticketFieldOneOfFilter?.startDateFilter != null) {
                    const startDate =
                      f.ticketFieldOneOfFilter?.startDateFilter.value
                    // RFC3339形式の文字列を日付・時間・分の三つに分ける
                    const [date, time] = startDate.split('T')
                    const formattedDate = date.replace(/-/g, '/')
                    const [hour, minute] = time.split(':')
                    results.push({
                      identifierName: f.ticketFieldName,
                      ticketCustomFieldId: null,
                      filter: {
                        startDate: formattedDate,
                        startHour: hour,
                        startMinute: minute,
                      },
                    })
                  }
                  if (f.ticketFieldOneOfFilter?.endDateFilter != null) {
                    const endDate =
                      f.ticketFieldOneOfFilter?.endDateFilter.value
                    // RFC3339形式の文字列を日付・時間・分の三つに分ける
                    const [date, time] = endDate.split('T')
                    const formattedDate = date.replace(/-/g, '/')
                    const [hour, minute] = time.split(':')
                    results.push({
                      identifierName: f.ticketFieldName,
                      ticketCustomFieldId: null,
                      filter: {
                        endDate: formattedDate,
                        endHour: hour,
                        endMinute: minute,
                      },
                    })
                  }
                  if (f.ticketFieldOneOfFilter?.dateFilter != null) {
                    const startDate =
                      f.ticketFieldOneOfFilter?.dateFilter.startDate
                    const endDate = f.ticketFieldOneOfFilter?.dateFilter.endDate
                    // RFC3339形式の文字列を日付・時間・分の三つに分ける
                    const [startDateDate, startTime] = startDate.split('T')
                    const [endDateDate, endTime] = endDate.split('T')
                    const formattedStartDate = startDateDate.replace(/-/g, '/')
                    const formattedEndDate = endDateDate.replace(/-/g, '/')
                    const [startHour, startMinute] = startTime.split(':')
                    const [endHour, endMinute] = endTime.split(':')
                    results.push({
                      identifierName: f.ticketFieldName,
                      ticketCustomFieldId: null,
                      filter: {
                        startDate: formattedStartDate,
                        startHour,
                        startMinute,
                        endDate: formattedEndDate,
                        endHour,
                        endMinute,
                      },
                    })
                  }
                  break
                }
                default:
                  break
              }
            }
            return results
          },
          []
        )
      }

      if (tabSortConfig != null) {
        if (tabSortConfig.ticketCustomFieldId !== 0) {
          const customField = action.payload.customFields.find(
            (f) => f.id === tabSortConfig.ticketCustomFieldId
          )
          state.sort = {
            identifierName:
              `custom.${tabSortConfig.ticketCustomFieldId}.${customField?.name}` ??
              '',
            ticketCustomFieldId: tabSortConfig.ticketCustomFieldId,
            direction: tabSortConfig.isAsc ? 'asc' : 'desc',
          }
        } else {
          state.sort = {
            identifierName: tabSortConfig.ticketFieldName,
            ticketCustomFieldId: null,
            direction: tabSortConfig.isAsc ? 'asc' : 'desc',
          }
        }
      }

      // タブ設定によるフィールドはフィルターのデフォルト設定より後に追加開発されたため、
      // フィールドの初期設定が不足しているタブも存在する
      // 前方互換性のため以下のif分岐を用意
      if (tabConfigFieldsList.length > 0) {
        state.fields = tabConfigFieldsList.map((field) => {
          return {
            identifierName: field.name,
            ticketCustomFieldId: field.ticketCustomFieldId,
          }
        })
      } else {
        state.fields = initializeFieldConfigs(action.payload.customFields)
      }
    },
    getTabConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      if (code === grpc.Code.OK) {
        state.initialize = true
        return
      }
      consoleErrorWithAirbrake(message)
    },
    createTabConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
        saveEvent: microsoftTeams.settings.SaveEvent
      }>
    ) {
      const { code, message, saveEvent } = action.payload
      if (code === grpc.Code.OK) {
        saveEvent.notifySuccess()
        return
      }
      saveEvent.notifyFailure()
      consoleErrorWithAirbrake(message)
    },
    updateTabConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
        saveEvent: microsoftTeams.settings.SaveEvent
      }>
    ) {
      const { code, message, saveEvent } = action.payload
      if (code === grpc.Code.OK) {
        saveEvent.notifySuccess()
        return
      }
      saveEvent.notifyFailure()
      consoleErrorWithAirbrake(message)
    },
    setTabConfigFilterError(
      state: DefaultTabConfig,
      action: PayloadAction<{ identifierName: string }>
    ) {
      const filter = state.filters.find(
        (f: FilterConfig) => f.identifierName === action.payload.identifierName
      )
      if (filter != null)
        state.error.filterIdentifierNames.push(filter.identifierName)
    },
    clearTabConfigFilterError(
      state,
      action: PayloadAction<{ identifierName: string }>
    ) {
      const filter = state.filters.find(
        (f: FilterConfig) => f.identifierName === action.payload.identifierName
      )
      if (filter != null)
        state.error.filterIdentifierNames =
          state.error.filterIdentifierNames.filter((f: string) => {
            return f !== filter.identifierName
          })
    },
    setFaqStatuses(
      state,
      action: PayloadAction<{
        statuses: FaqStatus[]
        tabConfigFilterIndex: number
      }>
    ) {
      state.filters[action.payload.tabConfigFilterIndex].filter = {
        values: action.payload.statuses,
      }
    },
  },
})

export const {
  setInitialState,
  setStatuses,
  setAssignedUserIds,
  setStartDateTime,
  addField,
  moveFields,
  removeField,
  setTabConfigSortIdentifierName,
  setTabConfigSortTicketCustomFieldId,
  setTabConfigSortDirection,
  setTabConfigName,
  removeTabConfigName,
  setCustomSelectFilter,
  setCustomNumberFilterValue,
  setCustomDateFilterValue,
  setCustomTextFilterValue,
  setCustomTextAreaFilterValue,
  setCustomCheckboxFilterValue,
  resetFilter,
  getTabConfigOnEnd,
  getTabConfigOnMessage,
  createTabConfigOnEnd,
  updateTabConfigOnEnd,
  setTabConfigFilterError,
  clearTabConfigFilterError,
  setFaqStatuses,
} = tabConfigSlice.actions
export default tabConfigSlice.reducer

export const fetchTabConfig =
  (uuid: string, customFields: TicketCustomField.AsObject[]): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      tab_config_pb.GetNewTabConfigRequest,
      tab_config_pb.TabConfig
    >(tab_config_pb_service.TabConfigNewAPI.GetNewTabConfig)

    const req = new tab_config_pb.GetNewTabConfigRequest()

    req.setTabUuid(uuid)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        getTabConfigOnMessage({
          uuid,
          message: message.toObject(),
          customFields: customFields,
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(getTabConfigOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const createDefaultTabConfig =
  (
    uuid: string,
    defaultFields: FieldConfig[],
    defaultFilters: FilterConfig[],
    defaultSort: SortConfig,
    customFields: TicketCustomField.AsObject[],
    saveEvent: microsoftTeams.settings.SaveEvent,
    isDefaultTab: boolean
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<tab_config_pb.CreateNewTabConfigRequest, Empty>(
      tab_config_pb_service.TabConfigNewAPI.CreateNewTabConfig
    )
    const req = new tab_config_pb.CreateNewTabConfigRequest()
    req.setTabUuid(uuid)

    const tabConfigProto = new tab_config_pb.TabConfig()
    tabConfigProto.setTabConfigFieldsList(
      defaultFields.map((f) => {
        const field = new tab_config_pb.TabConfigField()
        field.setName(f.identifierName)
        if (f.ticketCustomFieldId != null)
          field.setTicketCustomFieldId(f.ticketCustomFieldId)
        return field
      })
    )
    tabConfigProto.setIsDefaultTab(isDefaultTab)

    const tabSortConfig = new tab_config_pb.TabSortConfig()
    tabSortConfig.setTicketFieldName(defaultSort.identifierName)
    tabSortConfig.setIsAsc(defaultSort.direction === 'asc')
    if (defaultSort.ticketCustomFieldId != null)
      tabSortConfig.setTicketCustomFieldId(defaultSort.ticketCustomFieldId)
    tabConfigProto.setTabSortConfig(tabSortConfig)

    const tabFilterConfigs = new tab_config_pb.TabFilterConfigs()

    tabFilterConfigs.setTabFiltersList(
      buildTabFilter(defaultFilters, customFields)
    )
    if (tabFilterConfigs.getTabFiltersList().length > 0)
      tabConfigProto.setTabFilterConfigs(tabFilterConfigs)
    req.setTabConfig(tabConfigProto)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      if (message != null) consoleErrorWithAirbrake(message)
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        saveEvent.notifyFailure()
        return
      }
      dispatch(createTabConfigOnEnd({ code, message, saveEvent }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateDefaultTabConfig =
  (
    uuid: string,
    defaultFields: FieldConfig[],
    defaultFilters: FilterConfig[],
    defaultSort: SortConfig,
    customFields: TicketCustomField.AsObject[],
    saveEvent: microsoftTeams.settings.SaveEvent,
    isDefaultTab: boolean
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<tab_config_pb.UpdateNewTabConfigRequest, Empty>(
      tab_config_pb_service.TabConfigNewAPI.UpdateNewTabConfig
    )

    const req = new tab_config_pb.UpdateNewTabConfigRequest()
    req.setTabUuid(uuid)

    const tabConfigProto = new tab_config_pb.TabConfig()
    tabConfigProto.setTabConfigFieldsList(
      defaultFields.map((f) => {
        const field = new tab_config_pb.TabConfigField()
        field.setName(f.identifierName)
        if (f.ticketCustomFieldId != null)
          field.setTicketCustomFieldId(f.ticketCustomFieldId)
        return field
      })
    )
    tabConfigProto.setIsDefaultTab(isDefaultTab)

    const tabSortConfig = new tab_config_pb.TabSortConfig()
    tabSortConfig.setTicketFieldName(defaultSort.identifierName)
    tabSortConfig.setIsAsc(defaultSort.direction === 'asc')
    if (defaultSort.ticketCustomFieldId != null)
      tabSortConfig.setTicketCustomFieldId(defaultSort.ticketCustomFieldId)
    tabConfigProto.setTabSortConfig(tabSortConfig)

    const tabFilterConfigs = new tab_config_pb.TabFilterConfigs()

    tabFilterConfigs.setTabFiltersList(
      buildTabFilter(defaultFilters, customFields)
    )
    if (tabFilterConfigs.getTabFiltersList().length > 0)
      tabConfigProto.setTabFilterConfigs(tabFilterConfigs)
    req.setTabConfig(tabConfigProto)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      if (message != null) consoleErrorWithAirbrake(message)
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        saveEvent.notifyFailure()
        return
      }
      dispatch(updateTabConfigOnEnd({ code, message, saveEvent }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const deleteTab =
  (tabUUID: string): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<tab_pb.DeleteTabRequest, Empty>(
      tab_pb_service.TabAPI.DeleteTab
    )

    const req = new tab_pb.DeleteTabRequest()

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      if (code !== grpc.Code.OK) {
        consoleErrorWithAirbrake(message)
        return
      }
    })

    req.setEntityId(tabUUID)
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const buildTabFilter = (
  defaultFilters: FilterConfig[],
  customFields: TicketCustomField.AsObject[]
): tab_config_pb.TabFilter[] => {
  const tabfilters = defaultFilters
    .map((f) => {
      const tabFilter = new tab_config_pb.TabFilter()
      const ticketFieldOneOfFilter = new tab_config_pb.TicketFieldOneOfFilter()
      if (f.ticketCustomFieldId != null) {
        tabFilter.setTicketCustomFieldId(f.ticketCustomFieldId)
        // customFieldのフィルタを作成する処理
        const customField = customFields.find((c) => {
          return c.id === f.ticketCustomFieldId
        })
        switch (customField?.type) {
          case CustomFieldType.select:
          case CustomFieldType.multiselect:
            if (isCustomSelectValueFilter(f.filter)) {
              const stringFilter = new tab_config_pb.StringFilter()
              stringFilter.setValuesList(f.filter.values)
              ticketFieldOneOfFilter.setStringFilter(stringFilter)
              tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
            }
            break
          case CustomFieldType.checkbox:
            if (isCustomCheckboxValueFilter(f.filter)) {
              const checkboxFilter = new tab_config_pb.CheckboxFilter()
              checkboxFilter.setValuesList(f.filter.values)
              ticketFieldOneOfFilter.setCheckboxFilter(checkboxFilter)
              tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
            }
            break
          case CustomFieldType.number:
            if (isCustomNumberValueFilter(f.filter)) {
              const numberFilter = new tab_config_pb.NumberFilter()
              if (f.filter.maxValue != null)
                numberFilter.setMaxValue(f.filter.maxValue)
              if (f.filter.minValue != null)
                numberFilter.setMinValue(f.filter.minValue)
              if (numberFilter.hasMaxValue() || numberFilter.hasMinValue()) {
                ticketFieldOneOfFilter.setNumberFilter(numberFilter)
                tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
              }
            }
            break
          case CustomFieldType.textarea:
            if (isCustomTextValueFilter(f.filter)) {
              const textAreaFilter = new tab_config_pb.TextFilter()
              textAreaFilter.setStringValue(f.filter.value)
              ticketFieldOneOfFilter.setTextFilter(textAreaFilter)
              tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
            }
            break
          case CustomFieldType.date:
            if (isCustomDateValueFilter(f.filter)) {
              const dateFilter = new tab_config_pb.DateFilter()
              if (f.filter.startDate != null) {
                const [year, month, day] = f.filter.startDate.split('/')
                // RFC3339形式に文字列を作る. Dateに変換してからtoISOString()を使うと、タイムゾーンがUTCになってしまうため、文字列を作る
                const isoString = `${year}-${month}-${day}T00:00:00Z`
                dateFilter.setStartDate(isoString)
              }
              if (f.filter.endDate != null) {
                const [year, month, day] = f.filter.endDate.split('/')
                const isoString = `${year}-${month}-${day}T00:00:00Z`
                dateFilter.setEndDate(isoString)
              }
              if (dateFilter.hasStartDate() || dateFilter.hasEndDate()) {
                ticketFieldOneOfFilter.setDateFilter(dateFilter)
                tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
              }
            }
            break
          case CustomFieldType.text:
            if (isCustomTextValueFilter(f.filter)) {
              const stringFilter = new tab_config_pb.StringFilter()
              stringFilter.setValuesList([f.filter.value])
              ticketFieldOneOfFilter.setStringFilter(stringFilter)
              tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
            }
            break
          default:
            break
        }
      } else {
        tabFilter.setTicketFieldName(f.identifierName)
        switch (f.identifierName) {
          case DefaultFields.AssignedUserId.value:
          case DefaultFields.Status.value:
          case DefaultFields.FaqStatus.value: {
            const stringFilter = new tab_config_pb.StringFilter()
            if (isStringValueFilter(f.filter)) {
              stringFilter.setValuesList(f.filter.values)
              ticketFieldOneOfFilter.setStringFilter(stringFilter)
              tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
            }
            break
          }
          case DefaultFields.CreatedAt.value: {
            const dateFilter = new tab_config_pb.DateFilter()
            if (isCreatedAtValueFilter(f.filter)) {
              // 各項目からfromDate, toDateを作成する(RFC3339)
              if (
                f.filter.startDate != null &&
                f.filter.startHour != null &&
                f.filter.startMinute != null
              ) {
                const [year, month, day] = f.filter.startDate.split('/')
                // RFC3339形式に文字列を作る. Dateに変換してからtoISOString()を使うと、タイムゾーンがUTCになってしまうため、文字列を作る
                const isoString = `${year}-${month}-${day}T${f.filter.startHour}:${f.filter.startMinute}:00Z`
                dateFilter.setStartDate(isoString)
              }
              if (
                f.filter.endDate != null &&
                f.filter.endHour != null &&
                f.filter.endMinute != null
              ) {
                const [year, month, day] = f.filter.endDate.split('/')
                const isoString = `${year}-${month}-${day}T${f.filter.endHour}:${f.filter.endMinute}:00Z`
                dateFilter.setEndDate(isoString)
              }
              if (dateFilter.hasStartDate() || dateFilter.hasEndDate()) {
                ticketFieldOneOfFilter.setDateFilter(dateFilter)
                tabFilter.setTicketFieldOneOfFilter(ticketFieldOneOfFilter)
              }
            }
            break
          }
        }
      }
      if (!tabFilter.hasTicketFieldOneOfFilter()) return
      return tabFilter
    })
    .filter((v): v is tab_config_pb.TabFilter => v != null)
  return tabfilters
}

export type StoredDefaultFieldJSONData = {
  field: string
  ticket_custom_field_id: number | null
}

export type StoredDefaultFilterJSONData = {
  field: string
  ticket_custom_field_id: number | null
  filter: Filter
}

export type SortConfigJSONData = {
  identifier_name: string
  ticket_custom_field_id: number | null
  direction: string
}

export type StoredDefaultTabConfig = {
  fields: StoredDefaultFieldJSONData[]
  filters: StoredDefaultFilterJSONData[]
  sort: SortConfigJSONData
}

// JSON.parseによる得られる変数はAny型なので以下の関数でJSONオブジェクトの内部構造を検証する
// サーバーサイドから取得したJSONデータを利用する際は必ず使用する
export const isIncludeStoredDefaultTabConfigFilterObject = (
  jsonObj: unknown
): jsonObj is Omit<StoredDefaultTabConfig, 'sort'> => {
  return isPropertyAccessible(jsonObj) && typeof jsonObj.filters === 'object'
}

export const isIncludeStoredDefaultTabConfigSortObject = (
  jsonObj: unknown
): jsonObj is Omit<StoredDefaultTabConfig, 'filters'> => {
  return isPropertyAccessible(jsonObj) && typeof jsonObj.sort === 'object'
}

const isPropertyAccessible = (obj: unknown): obj is Record<string, unknown> => {
  return obj != null
}
