import { grpc } from '@improbable-eng/grpc-web'
import {
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import tab_channel_pb from '../../proto/tab_channel_pb'
import tab_channel_pb_service from '../../proto/tab_channel_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export const tabsAdapter =
  createEntityAdapter<tab_channel_pb.TabChannel.AsObject>({
    selectId: (t) => t.channelId || '',
  })

interface TabChannelState {
  loading: boolean
  error: string | null
}

export const tabChannelsSlice = createSlice({
  name: 'tab_channels',
  initialState: tabsAdapter.getInitialState<TabChannelState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchTabChannelsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchTabChannelsOnMessage(
      state,
      action: PayloadAction<{
        message: tab_channel_pb.GetTabChannelsResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      tabsAdapter.setAll(state, message.tabChannelsList)
    },
    fetchTabChannelsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchTabChannelsStart,
  fetchTabChannelsOnMessage,
  fetchTabChannelsOnEnd,
} = tabChannelsSlice.actions
export default tabChannelsSlice.reducer

export const fetchTabs =
  (groupId: string, channelIds: EntityId[]): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(fetchTabChannelsStart())

    const client = grpcClient<
      tab_channel_pb.GetTabChannelsRequest,
      tab_channel_pb.GetTabChannelsResponse
    >(tab_channel_pb_service.TabChannelAPI.GetTabChannels)

    const req = new tab_channel_pb.GetTabChannelsRequest()
    req.setGroupId(groupId)
    req.setChannelIdsList(channelIds.map((id) => String(id)))

    client.onMessage((message) => {
      dispatch(fetchTabChannelsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchTabChannelsOnEnd({ code, message }))
    })
    dispatch(fetchTabChannelsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
